// put business related bankruptcy info here
import React from "react"
import Helmet from "react-helmet"
import Layout from "../../../components/layout"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import ContactForm from "../../../components/contact-us-form"
import PageHeader from "../../../components/page-header"
import PracticeAreas from "../../../components/practice-areas"

export default () => (
	<Layout style={{ color: `teal` }}>
		<Helmet title="We Solve Business Bankruptcy | RWK Attorneys" defer={false} />

		<PageHeader>
			<Row>
				<Col>
					<nav aria-label="breadcrumb">
						<ol className="breadcrumb pb-0">
							<li class="breadcrumb-item"><Link to="/">Home</Link></li>
							<li class="breadcrumb-item"><Link to="/practice-areas">Practice Areas</Link></li>
							<li class="breadcrumb-item"><Link to="/practice-areas/bankruptcy">Bankruptcy</Link></li>
							<li class="breadcrumb-item active" aria-current="page">Business Bankruptcy</li>
						</ol>
					</nav>
					<h1>Business Bankruptcy<br /><small>Bankruptcy Law</small></h1>
				</Col>
			</Row>
		</PageHeader>


		<Container className="my-5">
			<Row>
				<Col md="3">
					<PracticeAreas />

					<ContactForm />
				</Col>
				<Col>
					<Row>
						<Col>
							<h1>Business Bankruptcy</h1>

							<p>You worked hard to grow your business, but hard times have led to significant debt. If you are looking for a way to solve your debt problems while protecting yourself from personal liability, the attorneys at Russo, White &amp; Keller, P.C., can help you explore your legal options. We are experienced bankruptcy lawyers who represent businesses and individuals across the state of Alabama. If you decide filing for small business bankruptcy is right for your company, we will provide you with skilled legal representation throughout the process. Call us or contact us online for a free consultation with an Alabama bankruptcy attorney. It could be your first step on the road to debt relief.</p>
						</Col>
					</Row>
					<Row className="mt-5 mb-4">
						<Col>
							<h2 className="text-center">What we can do for your business</h2>
						</Col>
					</Row>
					<Row>
						<Col>
							<h3>Evaluate</h3>
							<p>We evaluate your business and personal situation to determine a strategy best suited to reduce your business debt.</p>
						</Col>
						<Col>
							<h3>Stay in Control</h3>
							<p>We have debt consolidation strategies that are designed to avoid bankruptcy. No lengthy legal battles or attorney fees. Just a straightforward business strategy that works and eliminates debt.</p>
						</Col>
						<Col>
							<h3>Get on Track</h3>
							<p>Debt reduction is just one element of a successful turnaround. We not only eliminate debt for our clients, we get them on track to grow their businesses and reach new levels of success.</p>
						</Col>
					</Row>

					<Row>
						<Col>
							<h2 className="mt-5">Can Bankruptcy Save Your Business?</h2>
							<p>We represent a wide range of businesses in bankruptcy proceedings, including sole proprietorships, limited liability companies (LLCs) and partnerships. We understand how unique your debt problems are, and our attorneys will work with you to craft a strategy that match the unique needs of your business. We will help you explore debt relief options that are available to you, including:</p>

							<ul>
								<li>Small business Chapter 7: liquidate business assets to pay off creditors, and generally marks the end of a business. A trustee will be appointed to take over the liquidation process, relieving you of the responsibility of dealing with your creditors.</li>
								<li>Small business Chapter 13: protect your business from eviction, IRS collections and other creditor actions, while giving you time to get your business back on a stable footing. You can continue to operate the business with the regular income still in tact with the added benefit of just one payment to the Chapter 13 Trustee.</li>
							</ul>



							<h2 className="mt-5">Do You Need a Bankruptcy Attorney for Small Businesses Restructuring?</h2>
							<p>Chapter 11 bankruptcy is seldom advisable for small businesses. It is generally used for large corporations but no matter debt problems your business is facing, our lawyers will help you achieve your goals.</p>
						</Col>
					</Row>

					<Row>
						<Col>
						</Col>
					</Row>
				</Col>
			</Row>

		</Container>
	</Layout >
)